import React from "react";
import "./Supporters.css"; // Import CSS file for styling
import AutoCarousel from "../../ui/auto-carousel/AutoCarousel";

const Supporters = () => {
  const logos = [
    {
      name: "Blanc Fischer",
      src: "companyLogos/BFCS.png",
      url: "https://www.blanc-fischer.com/",
    },
    {
      name: "Airbus",
      src: "companyLogos/AirBus1.png",
      url: "https://www.airbus.com",
    },
    {
      name: "FoQee",
      src: "companyLogos/FoQee1.png",
      url: "https://www.foqee.de/",
    },
    {
      name: "INDYON",
      src: "companyLogos/INDYON1.png",
      url: "https://www.indyon.com",
    },
    {
      name: "InnoRoute",
      src: "companyLogos/InnoRoute.png",
      url: "https://innoroute.com/",
    },
    {
      name: "MBBM",
      src: "companyLogos/MBBM.png",
      url: "https://www.muellerbbm.com/homepage/",
    },
    {
      name: "Peri",
      src: "companyLogos/PERI1.png",
      url: "https://www.peri.com",
    },
    {
      name: "RUSC",
      src: "companyLogos/RUSC.png",
      url: "https://www.runds.de",
    },
    {
      name: "ScioSpec",
      src: "companyLogos/ScioSpec.png",
      url: "https://www.sciospec.com/",
    },
    {
      name: "VitaScale",
      src: "companyLogos/VitaScale1.png",
      url: "https://vitascale.com/",
    },
    {
      name: "WE_MTYE",
      src: "companyLogos/WE_MTYE.png",
      url: "https://www.we-online.com",
    },
    {
      name: "BlueSolve",
      src: "companyLogos/BlueSolve.png",
      url: "https://www.bluesolve.ai/",
    },
    {
      name: "WFG",
      src: "companyLogos/WFG.png",
      url: "https://wfgheilbronn.de/",
    },
    {
      name: "Bachert",
      src: "companyLogos/Bachert.jpg",
      url: "https://bachert-partner.de/",
    },
    {
      name: "Bayerische",
      src: "companyLogos/Bayerische.png",
      url: "https://www.diebayerische.de/",
    },
    {
      name: "Bayernlb",
      src: "companyLogos/Bayernlb.png",
      url: "https://www.bayernlb.de/internet/de/blb/resp/index.jsp",
    },
    {
      name: "Brainlab",
      src: "companyLogos/Brainlab.png",
      url: "https://www.brainlab.com/",
    },
    {
      name: "Canon",
      src: "companyLogos/Canon.png",
      url: "https://www.canon.de/",
    },
    {
      name: "Code&Co",
      src: "companyLogos/Code_Co.png",
      url: "https://www.codeandco.com/",
    },
    {
      name: "Giesecke",
      src: "companyLogos/Giesecke.jpg",
      url: "https://www.gi-de.com/de/",
    },
    {
      name: "Ibidi",
      src: "companyLogos/Ibidi.jpg",
      url: "https://ibidi.com/",
    },
    {
      name: "Infodas",
      src: "companyLogos/Infodas.png",
      url: "https://www.infodas.com/en/",
    },
    {
      name: "IPDnamics",
      src: "companyLogos/IPD.png",
      url: "https://www.ipdynamics.de/",
    },
    {
      name: "Itq",
      src: "companyLogos/Itq.jpg",
      url: "https://www.itq.de/en/",
    },
    ,
    {
      name: "Munzing",
      src: "companyLogos/Munzing.png",
      url: "https://www.munzing.com/de/",
    },
    {
      name: "Prosieben",
      src: "companyLogos/Prosieben.png",
      url: "https://www.prosieben.de/",
    },
    {
      name: "Steinecker",
      src: "companyLogos/Steinecker.png",
      url: "https://www.steinecker.com/de/index.php",
    },
    {
      name: "X-log",
      src: "companyLogos/X_log.png",
      url: "https://x-log.de/",
    },
    {
      name: "Bechtle",
      src: "companyLogos/Bechtle.png",
      url: "https://www.bechtle.com/",
    },
    {
      name: "Botcraft",
      src: "companyLogos/Botcraft.jpg",
      url: "https://botcraft.de/",
    },
    {
      name: "Cosmoshop",
      src: "companyLogos/Cosmoshop.png",
      url: "https://www.cosmoshop.de/",
    },
    {
      name: "FDTech",
      src: "companyLogos/FDTech.png",
      url: "https://www.fdtech.de/",
    },
    {
      name: "Lufthansa",
      src: "companyLogos/Flughafen_LH.png",
      url: "https://www.lufthansa.com/az/de/homepage",
    },
    {
      name: "Munich_Electrification",
      src: "companyLogos/ME.png",
      url: "https://www.munichelectrification.com/",
    },
    {
      name: "Pbb",
      src: "companyLogos/Pbb.jpg",
      url: "https://www.pfandbriefbank.com/index.html",
    },
    {
      name: "Rehau",
      src: "companyLogos/Rehau.jpg",
      url: "https://www.rehau.com/de-de",
    },
    {
      name: "SAP",
      src: "companyLogos/Sap.png",
      url: "https://www.sap.com/germany/index.html?url_id=auto_hp_redirect_germany",
    },
    {
      name: "Turner_Townsend",
      src: "companyLogos/Turner_Townsend.jpg",
      url: "https://www.turnerandtownsend.com/",
    },
  ];

  return (
    <div id="Supporters" style={{ padding: "20px 0px" }}>
      <AutoCarousel logos={logos} />
    </div>
  );
};

export default Supporters;
