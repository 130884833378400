import React, { useState } from "react";
import { Typography, Modal, Input, Button, Form, message } from "antd";
import { useLocation } from "react-router-dom";
import axios from "axios";

const { Text } = Typography;

const AntBanner = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();

  const handleSubscribeClick = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const handleSubscribe = async (values) => {
    try {
      const response = await axios.post("/api/subscriber/add", values);
      console.log("Subscription response:", response.data);
      message.success("Subscription successful!");
      handleCancel();
    } catch (error) {
      console.error("Subscription error:", error);
      message.error("Subscription failed. Please try again.");
    }
  };

  const displayMenu = location?.pathname === "/";
  if (!displayMenu) return null;

  return (
    <div style={{ position: "sticky", top: 0, zIndex: 999 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px 10px",
          textAlign: "center",
        }}
        className="thousand-orange"
      >
        <Text
          style={{
            color: "#fff",
            fontWeight: "600",
            fontSize: "14px",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={handleSubscribeClick}
        >
          Next 1.000+ Project Week - Subscribe
        </Text>
      </div>

      <Modal
        // title="Subscribe as a company to 1.000+ project week updates"
        open={showModal}
        onCancel={handleCancel}
        footer={null}
        style={{ textAlign: "center" }}
      >
        <h4 style={{ color: "#DB6C1F" }}>
          Subscribe as a company to 1.000+ project week updates
        </h4>
        <Form form={form} layout="vertical" onFinish={handleSubscribe}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              { required: true, message: "Please enter your first name" },
            ]}
          >
            <Input placeholder="Enter first name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: "Please enter your last name" }]}
          >
            <Input placeholder="Enter last name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please enter a valid email",
                type: "email",
              },
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>
          <Form.Item
            name="company"
            label="Company Name"
            rules={[
              { required: true, message: "Please enter your company name" },
            ]}
          >
            <Input placeholder="Enter company name" />
          </Form.Item>
          <Form.Item name="position" label="Position (Optional)">
            <Input placeholder="Enter position (optional)" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Subscribe
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AntBanner;
