import React, { useState } from "react";
import SignupButton from "../../ui/button/SignupButton";
import config from "../../../config";
import { Modal } from "antd";
import Button from "../../ui/button/Button";
import { useHistory } from "react-router-dom";

const backendUrl = process.env.REACT_APP_BACKEND_URL || "";

const WhyAttend = () => {
  const history = useHistory();
  const [showSignUpClosed, setShowSignUpClosed] = useState(false);
  const [showSignUpClosedCompanies, setShowSignUpClosedCompanies] =
    useState(false);
  const isShowModalToStudentsAndCompanies =
    config?.featureFlags?.showModalToStudentsAndCompanies;

  const handleBack = () => {
    setShowSignUpClosed(false);
  };

  const handleBackCompanies = () => {
    setShowSignUpClosedCompanies(false);
  };
  const params = new URLSearchParams({
    target: `${window.location.origin}/ssologin`,
  });

  const headingStyle = {
    fontStyle: "italic",
    fontWeight: "bold",
  };

  const headingStyle2 = {
    textAlign: "center",
    fontWeight: 600,
    padding: "40px",
    fontSize: "30px",
    color: "#313131",
    height: "75px",
  };

  const listItemStyle = {
    fontWeight: 400,
    fontSize: "17px",
    marginBottom: "8px",
    padding: "10px 0px",
  };

  const ulStyle = {
    paddingTop: "40px",
    textAlign: "left",
  };

  const HandleSignUpButton = () => {
    const today = new Date();
    const deadlineDate = new Date("2024-11-01");
    if (today > deadlineDate) {
      return (
        <a
          onClick={() => {
            setShowSignUpClosed(true);
          }}
        >
          <SignupButton text="SIGN UP WITH TUM CREDENTIALS" variant="primary" />
        </a>
      );
    } else {
      return (
        <div>
          <a href={`${backendUrl}/api/users/shibboleth/login/tum?${params}`}>
            <SignupButton
              text="SIGN UP WITH TUM CREDENTIALS"
              variant="primary"
            />
          </a>
        </div>
      );
    }
  };

  const HandleSignUpCompaniesButton = () => {
    if (isShowModalToStudentsAndCompanies) {
      return (
        <>
          <a onClick={() => setShowSignUpClosedCompanies(true)}>
            <SignupButton
              text="REGISTER YOUR COMPANY WITH US"
              variant="secondary"
            />
          </a>
          <Modal
            title={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px 0px",
                  textAlign: "center",
                }}
              >
                <h2 style={{ color: "#DB6C1F" }}>
                  Registration deadline had passed!
                </h2>
              </div>
            }
            open={showSignUpClosedCompanies}
            closable={false}
            keyboard={false}
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <Button
                  text="GO BACK TO HOMEPAGE"
                  onClick={handleBackCompanies}
                  variant={"secondary"}
                  style={{ color: "white" }}
                />
              </div>,
            ]}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "600",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              We are sorry to inform you that the signup phase for companies is
              over. We are happy to see you next time.
            </p>
          </Modal>
        </>
      );
    } else {
      return (
        <div>
          <SignupButton
            text="REGISTER YOUR COMPANY WITH US"
            variant="secondary"
            onClick={() => history.push("/register-company")}
          />
        </div>
      );
    }
  };

  return (
    <div>
      <h1 style={headingStyle2}>Don’t miss the chance to be part of 1.000+</h1>
      <section id="why-attend" className="why-attend-container">
        <div className="container">
          <div
            className="why-attend-left-section"
            style={{
              borderRadius: "10px",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {" "}
            <div>
              <h2 style={{ fontWeight: 800 }}>
                Why should I attend as a student?
              </h2>
              <ul style={ulStyle}>
                <li style={listItemStyle}>
                  Meet the German economic powerhouse
                </li>
                <li style={listItemStyle}>
                  Get to know corporate business and culture
                </li>
                <li style={listItemStyle}>
                  Exchange ideas with other students & TUM community
                </li>
                <li style={listItemStyle}>
                  Take the opportunity to solve real-life problems
                  (challenge-based learning)
                </li>
              </ul>
            </div>
            {HandleSignUpButton()}
          </div>
          <div
            className="why-attend-right-section"
            style={{
              borderRadius: "10px",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <h2 style={{ fontWeight: 800 }}>What can I gain as a company?</h2>
              <ul style={ulStyle}>
                <li style={listItemStyle}>
                  Connect with German & International TUM Talents
                </li>
                <li style={listItemStyle}>
                  Benefit from direct access to TUM & its community
                </li>
                <li style={listItemStyle}>
                  Meet potential future employees in the environment of the
                  company
                </li>
                <li style={listItemStyle}>
                  Learn from young TUM Talents and their innovative
                  problem-solving approach
                </li>
              </ul>
            </div>

            {HandleSignUpCompaniesButton()}
          </div>
        </div>
        <Modal
          title={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px 0px",
                textAlign: "center",
              }}
            >
              <h2 style={{ color: "#DB6C1F" }}>
                Registration deadline had passed!
              </h2>
            </div>
          }
          open={showSignUpClosed}
          closable={false}
          keyboard={false}
          footer={[
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                padding: "10px 0px",
              }}
            >
              <Button
                text="GO BACK TO HOMEPAGE"
                onClick={handleBack}
                variant={"secondary"}
                style={{ color: "white" }}
              />
            </div>,
          ]}
        >
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            We are sorry to inform you that the signup phase for students is
            over. We are happy to see you next time.
          </p>
        </Modal>
      </section>
    </div>
  );
};

export default WhyAttend;
